import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

import * as api from '../../api';
import { updateTenant } from '../../api';

// Actions
export const SET_ACTIVE_TENANT = 'config-editor/tenants/SET_ACTIVE_TENANT';

export const GET_JWT_DATA_REQUEST = 'config-editor/tenants/GET_JWT_DATA_REQUEST';
export const GET_JWT_DATA_SUCCESS = 'config-editor/tenants/GET_JWT_DATA_SUCCESS';
export const GET_JWT_DATA_FAIL = 'config-editor/tenants/GET_JWT_DATA_FAIL';

// Default State
const defaultState = {
  loading: false,
  data: [],
  active: { value: '', label: '' },
  error: null,
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
  case SET_ACTIVE_TENANT:
    return {
      ...state,
      active: action.tenant,
    };

  case GET_JWT_DATA_SUCCESS: {
    const { tenants } = action.payload;
    const newTenants = Object.keys(tenants)
      .sort()
      .map(key => ({
        value: key,
        label: key,
        extendConfigurations: tenants[key].extendConfigurations || [],
        allowConfigurationEdit: tenants[key].allowConfigurationEdit,
      }));

    return {
      ...state,
      data: newTenants,
      active: newTenants[0],
    };
  }

  case GET_JWT_DATA_FAIL:
    return {
      ...state,
      error: action.error,
    };

  default:
    return state;
  }
}

// Action Creators
export const setActiveTenant = tenant => ({
  type: SET_ACTIVE_TENANT,
  tenant,
});

export const getJwtDataRequest = (fetchConfigRequest) => ({
  type: GET_JWT_DATA_REQUEST,
  fetchConfigRequest,
});

export const getJwtDataSuccess = payload => ({
  type: GET_JWT_DATA_SUCCESS,
  payload,
});

export const getJwtDataFail = error => ({
  type: GET_JWT_DATA_FAIL,
  error,
});

// sagas
export function* getJwtDataSaga({ fetchConfigRequest }) {
  try {
    const jwtData = yield call(api.getJWTDataFromDb);

    if (get(jwtData, 'response.status', 200) !== 200) {
      throw jwtData.response;
    }

    updateTenant(Object.keys(jwtData.tenants).sort()[0]);
    fetchConfigRequest();
    yield put(getJwtDataSuccess(jwtData));
  } catch ({ data: { message = '' }, status }) {
    yield put(getJwtDataFail(message));
  }
}
