// Actions
export const SHOW_MODAL = 'config-editor/modal/SHOW_MODAL';
export const HIDE_MODAL = 'config-editor/modal/HIDE_MODAL';
export const MODAL_TYPE_ERROR = 'config-editor/modal/MODAL_TYPE_ERROR';
export const MODAL_TYPE_SUCCESS = 'config-editor/modal/MODAL_TYPE_SUCCESS';
export const MODAL_TYPE_WARNING = 'config-editor/modal/MODAL_TYPE_WARNING';

// Default State
export const defaultState = {
  isModalVisible: false,
  modalType: '',
  modalTitle: '',
  modalBody: '',
  modalContinueButtonText: '',
  modalCloseButtonText: '',
  modalClassName: '',
  handleModalCloseButton: null,
  handleModalContinueButton: null,
  modalValidations: {},
  strictUpdatePayload: null,
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
  case SHOW_MODAL:

    return {
      ...state,
      isModalVisible: true,
      ...action.data,
    };

  case HIDE_MODAL:
    return defaultState;

  default:
    return state;
  }
}

// Action Creators
export const showModal = (
  modalTitle,
  modalType,
  modalBody,
  modalValidations = {},
  handleModalCloseButton,
  modalCloseButtonText,
  modalContinueButtonText,
  handleModalContinueButton,
  modalClassName = '',
  strictUpdatePayload = null,
) => ({
  type: SHOW_MODAL,
  data: {
    modalTitle,
    modalType,
    modalBody,
    modalValidations,
    handleModalCloseButton,
    modalCloseButtonText,
    modalContinueButtonText,
    handleModalContinueButton,
    modalClassName,
    strictUpdatePayload,
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
