import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS, MODAL_TYPE_WARNING } from '../../store/modules/modal';

import './InfoModal.scss';

const InfoModal = (props) => {
  const {
    modalTitle,
    isModalVisible,
    modalBody,
    handleModalClose,
    modalType,
    handleModalCloseButton,
    modalCloseButtonText,
    modalContinueButtonText,
    handleModalContinueButton,
    modalClassName,
    updateConfigRequest,
    strictUpdatePayload,
    modalValidations: {
      low_risk: lowRisk,
      high_risk: highRisk,
    },
  } = props;

  const handleCloseModal = () => {
    handleModalClose();

    if (handleModalCloseButton) {
      handleModalCloseButton();
    }

    if (strictUpdatePayload) {
      updateConfigRequest(strictUpdatePayload, 'commit=true&strictmode=false');
    }
  };

  const onClickContinueButton = () => {
    handleModalClose();

    if (handleModalContinueButton) {
      handleModalContinueButton();
    }
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseModal} className={`${modalClassName} info-modal`} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre
          className={classNames({
            'bg-danger text-danger': modalType === MODAL_TYPE_ERROR,
            'bg-success text-success': modalType === MODAL_TYPE_SUCCESS,
            'bg-warning text-warning': modalType === MODAL_TYPE_WARNING,
          })}
        >
          {modalBody}
        </pre>
        {!!highRisk?.length && (
          <pre
            className="bg-secondary text-secondary"
          >
            <div>
              <b>High Risks Validation Conflicts:</b>
              {highRisk.map(item => <div key={item}>{item}</div>)}
            </div>
          </pre>
        )}
        {!!lowRisk?.length && (
          <pre
            className="bg-secondary text-secondary"
          >
            <div>
              <b>High Risks Validation Conflicts:</b>
              {lowRisk.map(item => <div key={item}>{item}</div>)}
            </div>
          </pre>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>{modalCloseButtonText || 'Close'}</Button>
        {modalContinueButtonText
        && <Button className="second-button" onClick={onClickContinueButton}>{modalContinueButtonText}</Button>}
      </Modal.Footer>
    </Modal>
  );
};

InfoModal.defaultProps = {
  handleModalCloseButton: () => {},
  handleModalContinueButton: () => {},
  modalCloseButtonText: '',
  modalContinueButtonText: '',
  modalClassName: '',
  strictUpdatePayload: null,
  modalValidations: {},
};

InfoModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  modalBody: PropTypes.any.isRequired,
  modalType: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalCloseButton: PropTypes.func,
  handleModalContinueButton: PropTypes.func,
  modalCloseButtonText: PropTypes.string,
  modalContinueButtonText: PropTypes.string,
  modalClassName: PropTypes.string,
  modalValidations: PropTypes.shape({
    low_risk: PropTypes.array,
    high_risk: PropTypes.array,
  }),
  updateConfigRequest: PropTypes.func.isRequired,
  strictUpdatePayload: PropTypes.object,
};

export default InfoModal;
