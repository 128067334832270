export const getConfigState = ({ config }) => config;
export const getConfigData = ({ config: { config } }) => config;
export const getServerConfig = ({ config: { serverConfig } }) => serverConfig;
export const getResolvedConfigData = ({ config: { resolvedConfig } }) => resolvedConfig;
export const getConfigTempData = ({ config: { tempData } }) => tempData;
export const getConfigLoading = ({ config: { loading } }) => loading;
export const getConfigError = ({ config: { error } }) => error;
export const getConfigGlobalLoading = ({ config: { globalLoading } }) => globalLoading;
export const getRequestStatus = ({ config: { requestStatusCode } }) => requestStatusCode;
export const getIsSuccessJWTRequest = ({ config: { isSuccessJWTRequest } }) => isSuccessJWTRequest;
export const getServerResolvedConfig = ({ config: { serverResolvedConfig } }) => serverResolvedConfig;

export const getTenants = ({ tenants }) => tenants;
export const getTenantsLoading = ({ tenants: { loading } }) => loading;
export const getTenantsData = ({ tenants: { data } }) => data;
export const getActiveTenant = ({ tenants: { active } }) => active;
export const getTenantsError = ({ tenants: { error } }) => error;

export const getPanels = ({ layout: { panels } }) => panels;
export const getPanelsVisibile = ({ layout: { panels: { visible } } }) => visible;
export const getShowStandardConfig = ({ layout: { showStandardConfig } }) => showStandardConfig;

export const getNavlist = ({ layout: { navlist } }) => navlist;

export const getGraph = ({ layout: { graph } }) => graph;
export const getGraphLayout = ({ layout: { gridLayout } }) => gridLayout;

export const getActiveTab = ({ layout: { gridActiveTab } }) => gridActiveTab;

export const getAttributesRef = ({ layout: { attributesRef } }) => attributesRef;
export const getExtendedNamespacesRef = ({ layout: { extendedNamespacesRef } }) => extendedNamespacesRef;
export const getExcludedNamespacesRef = ({ layout: { excludedNamespacesRef } }) => excludedNamespacesRef;
export const getTablesRef = ({ layout: { tablesRef } }, tableName) => tablesRef[tableName];
export const getRelationsGridRef = ({ layout: { relationsGridRef } }) => relationsGridRef;

export const getSelectedEntity = ({ layout: { selectedEntity } }) => selectedEntity;
export const getSelectedEntityIndex = ({ layout: { selectEntityIndex } }) => selectEntityIndex;

export const getActiveTabState = ({ layout: { activeTabState } }) => activeTabState;

export const getAttributeTableExpandedRows = ({ layout: { attributeTableExpandedRows } }) => attributeTableExpandedRows;

export const getModalVisible = ({ modal: { isModalVisible } }) => isModalVisible;
export const getModalType = ({ modal: { modalType } }) => modalType;
export const getModalTitle = ({ modal: { modalTitle } }) => modalTitle;
export const getModalBody = ({ modal: { modalBody } }) => modalBody;
export const getModalValidations = ({ modal: { modalValidations } }) => modalValidations;
export const getStrictUpdatePayload = ({ modal: { strictUpdatePayload } }) => strictUpdatePayload;
export const getModalContinueButtonText = ({ modal: { modalContinueButtonText } }) => modalContinueButtonText;
export const getModalCloseButtonText = ({ modal: { modalCloseButtonText } }) => modalCloseButtonText;
export const getHandleModalContinueButton = ({ modal: { handleModalContinueButton } }) => handleModalContinueButton;
export const getModalClassName = ({ modal: { modalClassName } }) => modalClassName;
export const getHandleModalCloseButton = ({ modal: { handleModalCloseButton } }) => handleModalCloseButton;
